import logger from '@/utils/logger';
import { type ChangeEvent, type ReactElement, useEffect, useState } from 'react';
import { createPatient, updatePatient } from 'services/patient';
import Modal from '~/components/tailgrids/Modal';
import AddressTypeahead from '~/components/AddressTypeahead';
import type { IPatient } from 'types/mylaurel/IPatient';
import { dayjs } from '~/utils';
import EmailInput from '~/components/EmailInput';
import PhoneInput from '~/components/PhoneInput';
import LanguageSelector from '~/components/LanguageSelect';
import getUserInfo from '~/utils/getUserInfo';
import { titleCase } from '~/utils/stringTransforms';
import { useFetcher, useNavigate, useSearchParams } from '@remix-run/react';
import { type ActionFunction } from '@remix-run/node';

import validate from 'validator';
import ErrorMessage from '~/components/ErrorMessage';
import InputWrapper from '~/components/InputWrapper';
import {
  LucideBuilding2,
  LucideCake,
  LucideCircleAlert,
  LucideCircleUserRound,
  LucideClipboardPaste,
  LucideGlobe,
  LucideShieldCheck,
  LucideUsersRound,
} from 'lucide-react';

export const action: ActionFunction = async ({ request }) => {
  const user = await getUserInfo(request);
  const data: Partial<IPatient> = Object.fromEntries(await request.formData());
  const redirectUrl = request.headers.get('Referer') ?? '';

  // Validate form before submitting
  const validationErrors = validateForm(data);

  if (Object.values(validationErrors).some(error => error)) {
    return { validationErrors };
  }

  // Process and format data
  data.consentToCall = String(data.consentToCall) === 'on';
  data.consentToText = String(data.consentToText) === 'on';
  data.unstablyHoused = String(data.unstablyHoused) === 'on';
  data.dob = dayjs(String(data.dob)).format('MM/DD/YYYY');
  // Ensure phone numbers are only numbers
  data.homePhone = String(data.homePhone || '').replace(/[^\d.-]+/g, '');
  data.mobilePhone = String(data.mobilePhone || '').replace(/[^\d.-]+/g, '');

  if (data.patientId) {
    // Update Patient
    try {
      await updatePatient(data.patientId, data);

      return {
        success: true,
        message: 'Patient updated successfully',
        redirectTo: redirectUrl,
      };
    } catch (e: any) {
      logger.error({
        error: e,
        user: user.email,
        route: 'app/routes/__dashboard/components/Patients/CreateOrUpdatePatientFormModal.tsx',
      });
      return {
        error: true,
        message: e?.response?.data?.error || e.message,
      };
    }
  } else {
    try {
      // Create Patient
      const potentialPatient: IPatient = {
        patientId: data.patientId,
        firstName: data?.firstName ?? '',
        lastName: data?.lastName ?? '',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        dob: dayjs(data.dob).format('MM/DD/YYYY'),
        sex: data.sex,
        email: data.email,
        preferredLanguage: data.language6392Code,
        language6392Code: data.language6392Code,
        homePhone: data.homePhone,
        mobilePhone: data.mobilePhone,
        consentToCall: data.consentToCall,
        consentToText: data.consentToText,
        partnerName: data.partnerName,
        partnerMemberId: data.partnerMemberId,
        race: data.race,
        ethnicityCode: data.ethnicityCode,
        maritalStatus: data.maritalStatus,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        unstablyHoused: data.unstablyHoused,
      };

      const patient = await createPatient(potentialPatient);

      return { athenaPatientId: patient?.patientId };
    } catch (e: any) {
      logger.error({
        error: e,
        user: user.email,
        route: 'app/routes/__dashboard/components/Patients/CreateOrUpdatePatientFormModal.tsx',
      });
      return { error: true, message: e.message };
    }
  }
};

const validateForm = (formData: any): Record<string, string> => {
  const requiredFields = {
    firstName: 'First Name',
    lastName: 'Last Name',
    dob: 'Date of Birth',
    sex: 'Sex at Birth',
    address1: 'Home Address',
    partnerName: 'Partner Name',
    partnerMemberId: 'Partner Member ID',
  };

  const errors = {};

  for (const [key, label] of Object.entries(requiredFields)) {
    if (!formData[key]) {
      errors[key] = `${label} is required.`;
    }
  }
  return errors;
};

export function CreateOrUpdatePatientFormModal({
  patient,
  isOpen,
  onHide,
  className = '',
  partnerNames = [],
}: {
  patient?: SerializeFrom<IPatient>;
  isOpen: boolean;
  onHide: () => void;
  className?: string;
  partnerNames: string[];
}): ReactElement {
  const fetcher = useFetcher<typeof action>();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [emailDeclined, setEmailDeclined] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [isMobilePhoneValid, setIsMobilePhoneValid] = useState<boolean | null>(null);
  const [isHomePhoneValid, setIsHomePhoneValid] = useState<boolean | null>(null);

  const [patientInput, setPatientInput] = useState<IPatient>({
    patientId: patient?.patientId ?? '',
    departmentId: patient?.departmentId ?? '',
    firstName: patient?.firstName ?? '',
    lastName: patient?.lastName ?? '',
    dob: patient?.dob ? dayjs(patient?.dob).format('YYYY-MM-DD') : '',
    sex: patient?.sex ?? '',
    email: patient?.email ?? '',
    preferredLanguage: patient?.language6392Code || 'eng',
    homePhone: patient?.homePhone ?? '',
    mobilePhone: patient?.mobilePhone ?? '',
    consentToCall: patient?.consentToCall ?? false,
    consentToText: patient?.consentToText ?? false,
    partnerName: patient?.partnerName ?? '',
    partnerMemberId: patient?.partnerMemberId ?? '',
    race: patient?.race ?? '',
    ethnicityCode: patient?.ethnicityCode ?? '',
    maritalStatus: patient?.maritalStatus ?? '',
    address1: patient?.address1 ?? '',
    address2: patient?.address2 ?? '',
    city: patient?.city ?? '',
    state: patient?.state ?? '',
    zip: patient?.zip ?? '',
    unstablyHoused: patient?.databasePatient?.unstablyHoused ?? false,
  });

  const handleOnChange = (e): void => {
    const { name, value, type, checked } = e.target;

    setPatientInput(prevValues => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // On successful patient update
  useEffect(() => {
    if (fetcher.data?.success) {
      if (params.get('source') === 'patientSearch') {
        // Navigate to the patientId page
        navigate(`/patients/${patient?.patientId}`);
      }
      onHide();
    }
  }, [fetcher.data, navigate, onHide, params, patient?.patientId]);

  // On successful patient creation, navigate to patientId page
  useEffect(() => {
    if (fetcher?.data?.athenaPatientId) {
      onHide();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      navigate(`/patients/${fetcher.data.athenaPatientId}`);
    }
  }, [fetcher?.data, navigate, onHide]);

  // Validate email and phone numbers
  useEffect(() => {
    if (emailDeclined) {
      setIsEmailValid(true);
    } else if (patientInput.email) {
      setIsEmailValid(validate.isEmail(patientInput.email));
    }
  }, [patientInput.email, emailDeclined]);
  useEffect(() => {
    if (patientInput.mobilePhone) {
      setIsMobilePhoneValid(validate.isMobilePhone(patientInput.mobilePhone));
    }
  }, [patientInput.mobilePhone]);
  useEffect(() => {
    if (patientInput.homePhone) {
      setIsHomePhoneValid(validate.isMobilePhone(patientInput.homePhone));
    }
  }, [patientInput.homePhone]);

  const raceOptions = [
    { value: '0', label: 'None' },
    { value: '1002-5', label: 'American Indian or Alaskan Native' },
    { value: '2028-9', label: 'Asian' },
    { value: '2054-5', label: 'Black or African American' },
    { value: '2131-1', label: 'Other race' },
    { value: '2106-3', label: 'White' },
  ];

  const ethnicities = [
    { value: '0', label: 'None' },
    { value: '2155-0', label: 'Central American' },
    { value: '2182-4', label: 'Cuban' },
    { value: '2184-0', label: 'Dominican' },
    { value: '2135-2', label: 'Hispanic or Latino/Spanish' },
    { value: '2178-2', label: 'Latin American/Latin, Latino' },
    { value: '2148-5', label: 'Mexican' },
    { value: '2186-5', label: 'Not Hispanic or Latino' },
    { value: '2180-8', label: 'Puerto Rican' },
    { value: '2165-9', label: 'South American' },
    { value: '2137-8', label: 'Spaniard' },
  ];

  const maritalStatusOptions = [
    { value: 'U', label: 'Unknown' },
    { value: 'D', label: 'Divorced' },
    { value: 'M', label: 'Married' },
    { value: 'P', label: 'Partner' },
    { value: 'S', label: 'Single' },
    { value: 'X', label: 'Separated' },
    { value: 'W', label: 'Widowed' },
  ];

  return (
    <Modal
      show={isOpen}
      onHide={onHide}
      modalClass={`w-[890px] h-[575px] bg-gray-100 px-7`}
      showXIcon={false}
      disableClickOutside={true}
    >
      <fetcher.Form method="post" action={'/components/Patients/CreateOrUpdatePatientFormModal'} className={className}>
        <input type="hidden" name="patientId" value={patientInput.patientId} />
        <input type="hidden" name="departmentId" value={patientInput.departmentId} />
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col">
            <h3 className="text-[#2E1065] font-extrabold mb-3 ml-1">Patient Information</h3>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-2">
                <label htmlFor="firstName">
                  <div>
                    <InputWrapper
                      type="text"
                      name="firstName"
                      className={`w-full ${fetcher.data?.validationErrors?.firstName && !patientInput.firstName ? 'border-red-500' : ''}`}
                      defaultValue={patientInput.firstName}
                      onChange={handleOnChange}
                      startAdornment={<LucideCircleUserRound className="h-4 w-4" />}
                      endAdornment={
                        fetcher.data?.validationErrors?.firstName && !patientInput.firstName ? (
                          <LucideCircleAlert className="h-4 w-4 text-red-500" />
                        ) : null
                      }
                    />
                  </div>
                  {fetcher.data?.validationErrors?.firstName && !patientInput.firstName ? (
                    <span className={'text-red-500 text-xs'}>{fetcher.data?.validationErrors?.firstName}</span>
                  ) : (
                    'First Name'
                  )}
                </label>
              </div>
              <div className="col-span-2">
                <label htmlFor="lastName">
                  <InputWrapper
                    type="text"
                    name="lastName"
                    className={`w-full ${fetcher.data?.validationErrors?.lastName && !patientInput.lastName ? 'border-red-500' : ''}`}
                    defaultValue={patientInput.lastName}
                    onChange={handleOnChange}
                    startAdornment={<LucideCircleUserRound className="h-4 w-4" />}
                    endAdornment={
                      fetcher.data?.validationErrors?.lastName && !patientInput.lastName ? (
                        <LucideCircleAlert className="h-4 w-4 text-red-500" />
                      ) : null
                    }
                  />
                  {fetcher.data?.validationErrors?.lastName && !patientInput.lastName ? (
                    <span className={'text-red-500 text-xs'}>{fetcher.data?.validationErrors?.lastName}</span>
                  ) : (
                    'Last Name'
                  )}
                </label>
              </div>
              <div className="col-span-1">
                <label htmlFor="dob">
                  <InputWrapper
                    type="date"
                    placeholder="mm/dd/yyyy"
                    name="dob"
                    className={`w-full ${fetcher.data?.validationErrors?.dob && !patientInput.dob ? 'border-red-500' : ''}`}
                    defaultValue={patientInput.dob}
                    onChange={handleOnChange}
                    startAdornment={<LucideCake className="h-4 w-4" />}
                    endAdornment={
                      fetcher.data?.validationErrors?.dob && !patientInput.dob ? (
                        <LucideCircleAlert className="h-4 w-4 text-red-500" />
                      ) : null
                    }
                  />
                  {fetcher.data?.validationErrors?.dob && !patientInput.dob ? (
                    <span className={'text-red-500 text-xs'}>{fetcher.data?.validationErrors?.dob}</span>
                  ) : (
                    'Date of Birth'
                  )}
                </label>
              </div>
              <div className="col-span-3">
                <AddressTypeahead
                  address={{
                    address1: patientInput?.address1 ?? '',
                    address2: patientInput?.address2 ?? '',
                    city: patientInput?.city ?? '',
                    state: patientInput?.state ?? '',
                    zip: patientInput?.zip ?? '',
                  }}
                  className="w-full ml-1"
                  placeholder="Start typing an address..."
                  showAddressLabel
                  showAddress2
                  showAddress2Label
                  errorState={fetcher.data?.validationErrors?.address1}
                />
              </div>
              <div className="flex cursor-pointer gap-1 col-span-1 ml-5 mt-3">
                <input
                  id={'unstablyHoused'}
                  name={'unstablyHoused'}
                  type="checkbox"
                  checked={patientInput.unstablyHoused}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setPatientInput({ ...patientInput, unstablyHoused: !patientInput.unstablyHoused });
                  }}
                />
                <label htmlFor={'unstablyHoused'} className="text-[12px]">
                  Unstably Housed
                </label>
              </div>
              <div className="col-span-1">
                <label htmlFor="sex">
                  <input type="hidden" value={patientInput.sex} name="sex" />
                  <div className="flex gap-x-2 mb-1">
                    <button
                      name="sex"
                      value="M"
                      type="button"
                      onClick={() => {
                        setPatientInput({ ...patientInput, sex: 'M' });
                      }}
                      className={
                        patientInput.sex === 'M'
                          ? 'btn-primary w-1/2 flex items-center'
                          : 'btn-secondary bg-white w-1/2 flex items-center'
                      }
                    >
                      Male
                    </button>
                    <button
                      name="sex"
                      value="F"
                      type="button"
                      onClick={() => {
                        setPatientInput({ ...patientInput, sex: 'F' });
                      }}
                      className={
                        patientInput.sex === 'F'
                          ? 'btn-primary w-1/2 flex items-center'
                          : 'btn-secondary bg-white w-1/2 flex items-center'
                      }
                    >
                      Female
                    </button>
                  </div>
                  {fetcher.data?.validationErrors?.sex && !patientInput.sex ? (
                    <span className={'text-red-500 text-xs'}>{fetcher.data?.validationErrors?.sex}</span>
                  ) : (
                    'Sex at Birth'
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="text-[#2E1065] font-extrabold mb-3 ml-1">Contact</h3>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-2">
                <label>
                  <EmailInput
                    name={emailDeclined ? 'emailTemp' : 'email'}
                    className="w-full"
                    disabled={emailDeclined}
                    defaultValue={patientInput.email}
                    onChange={handleOnChange}
                  />
                  <div className="flex items-center justify-between">
                    {isEmailValid !== null && !isEmailValid && !emailDeclined ? (
                      <div className={'text-red-500'}>Please enter a valid email address.</div>
                    ) : (
                      'Email'
                    )}
                    <input
                      type="hidden"
                      value="declined"
                      name={emailDeclined ? 'email' : 'emailTemp'}
                      className="w-full"
                    />
                    <div className="ml-1 flex items-center gap-3 text-sm">
                      <input
                        type="checkbox"
                        name="emailDeclined"
                        className="h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={e => {
                          setEmailDeclined(e.target.checked);
                        }}
                      />
                      <div className="text-[11px]">Declined to share email</div>
                    </div>
                  </div>
                </label>
              </div>
              <div className="col-span-1">
                <label htmlFor="homePhone">
                  <div className="flex items-center">
                    <PhoneInput
                      name="homePhone"
                      className="w-full pr-2"
                      value={patientInput.homePhone}
                      onChange={handleOnChange}
                      startAdornment={true}
                    />
                    <span>
                      <LucideClipboardPaste
                        className="h-5 w-5 -mr-4 pl-1 cursor-pointer hover:text-indigo-500"
                        onClick={() => {
                          setPatientInput({ ...patientInput, mobilePhone: patientInput.homePhone });
                        }}
                      />
                    </span>
                  </div>
                  {isHomePhoneValid !== null && !isHomePhoneValid ? (
                    <span className={'text-red-500 text-xs'}>Please enter a valid home phone number.</span>
                  ) : (
                    'Home Phone'
                  )}
                </label>
              </div>

              <div className="col-span-1">
                <label htmlFor="mobilePhone">
                  <PhoneInput
                    name="mobilePhone"
                    value={patientInput.mobilePhone}
                    className="w-full"
                    onChange={handleOnChange}
                    startAdornment={true}
                  />
                  {isMobilePhoneValid !== null && !isMobilePhoneValid ? (
                    <span className={'text-red-500'}>Please enter a valid mobile phone number.</span>
                  ) : (
                    'Mobile Phone'
                  )}
                </label>
              </div>
              <div className="col-span-1">
                <label htmlFor="language">
                  <LanguageSelector className="w-full pl-10" defaultLanguage={patientInput.preferredLanguage} />
                  Language
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <h3 className="text-[#2E1065] font-extrabold mb-3 ml-1">Details</h3>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-1">
                <label>
                  <div className="relative flex items-center justify-center mb-1">
                    <LucideUsersRound className="w-4 h-4 absolute left-3" />
                    <select
                      name="race"
                      defaultValue={patientInput.race}
                      className="w-full pl-10"
                      onChange={handleOnChange}
                      required
                    >
                      {raceOptions.map(race => {
                        return (
                          <option key={race.value} value={race.value}>
                            {race.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  Race
                </label>
              </div>
              <div className="col-span-1">
                <label>
                  <div className="relative flex items-center justify-center mb-1">
                    <LucideGlobe className="w-4 h-4 absolute left-3" />
                    <select
                      name="ethnicityCode"
                      defaultValue={patientInput.ethnicityCode}
                      className="w-full pl-10"
                      onChange={handleOnChange}
                      required
                    >
                      {ethnicities.map(ethnicity => {
                        return (
                          <option key={ethnicity.value} value={ethnicity.value}>
                            {ethnicity.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  Ethnicity
                </label>
              </div>
              <div className="col-span-1">
                <label>
                  <select
                    name="maritalStatus"
                    defaultValue={patientInput.maritalStatus}
                    className="w-full mb-1"
                    onChange={handleOnChange}
                    required
                  >
                    {maritalStatusOptions.map(maritalStatus => {
                      return (
                        <option key={maritalStatus.value} value={maritalStatus.value}>
                          {maritalStatus.label}
                        </option>
                      );
                    })}
                  </select>
                  Marital Status
                </label>
              </div>
              <div className="col-span-1">
                <label>
                  <div className="relative flex items-center justify-center mb-1">
                    <LucideBuilding2 className="w-4 h-4 absolute left-3" />
                    <select
                      name="partnerName"
                      className={`w-full pl-10 ${fetcher.data?.validationErrors?.partnerName && !patientInput.partnerName ? 'border-red-500' : ''}`}
                      defaultValue={patientInput.partnerName}
                      onChange={handleOnChange}
                    >
                      <option value="" hidden>
                        Select a Partner
                      </option>
                      {partnerNames.map((partner: string) => (
                        <option key={partner} value={partner.toUpperCase()}>
                          {titleCase(partner)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {fetcher.data?.validationErrors?.partnerName && !patientInput.partnerName ? (
                    <span className={'text-red-500 text-xs'}>{fetcher.data?.validationErrors?.partnerName}</span>
                  ) : (
                    'Partner Name'
                  )}
                </label>
              </div>
              <div className="col-span-1">
                <label>
                  <div className="relative flex items-center">
                    <InputWrapper
                      type="text"
                      placeholder="#______"
                      className={`w-full ${fetcher.data?.validationErrors?.partnerMemberId && !patientInput.partnerMemberId ? 'border-red-500' : ''}`}
                      name="partnerMemberId"
                      value={patientInput.partnerMemberId}
                      onChange={handleOnChange}
                      startAdornment={<LucideShieldCheck className="w-4 h-4" />}
                      endAdornment={
                        fetcher.data?.validationErrors?.partnerMemberId && !patientInput.partnerMemberId ? (
                          <LucideCircleAlert className="h-4 w-4 text-red-500" />
                        ) : null
                      }
                    />
                  </div>
                  {fetcher.data?.validationErrors?.partnerMemberId && !patientInput.partnerMemberId ? (
                    <span className={'text-red-500 text-xs'}>{fetcher.data?.validationErrors?.partnerMemberId}</span>
                  ) : (
                    ' Partner Member ID/Insurance ID'
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="text-[#2E1065] font-extrabold ml-1">Automated Reminder</h3>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-1">
                <div className="flex items-center gap-3 p-3">
                  <input
                    type="checkbox"
                    name="consentToCall"
                    defaultChecked={patientInput.consentToCall ?? false}
                    onChange={handleOnChange}
                    className="h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="text-sm">Consent to Call</span>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex items-center gap-3 p-3">
                  <input
                    type="checkbox"
                    name="consentToText"
                    defaultChecked={patientInput.consentToText ?? false}
                    onChange={handleOnChange}
                    className="h-4 w-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-500"
                    disabled={!patientInput.mobilePhone}
                  />
                  <span className="text-sm">
                    Consent to Text <span className="text-xs">(requires mobile phone number)</span>
                  </span>
                </div>
              </div>
              <div className="col-start-4 col-span-2 grid justify-items-end">
                <div className="flex">
                  <button
                    className="btn-secondary mr-3 bg-white"
                    onClick={() => {
                      if (params.get('source') === 'patientSearch') {
                        navigate(`/patients`, { replace: true });
                      }
                      onHide();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="w-30"
                    disabled={
                      fetcher.state === 'submitting' ||
                      fetcher.state === 'loading' ||
                      (isMobilePhoneValid !== null && !isMobilePhoneValid) ||
                      (isHomePhoneValid !== null && !isHomePhoneValid) ||
                      (isEmailValid !== null && !isEmailValid)
                    }
                  >
                    {fetcher.state === 'submitting' || fetcher.state === 'loading' ? 'Saving...' : 'Save Patient'}
                  </button>
                </div>
              </div>
            </div>

            {fetcher.data?.error ? <ErrorMessage error={fetcher.data?.message} className="mb-5" /> : null}
          </div>
        </div>
      </fetcher.Form>
    </Modal>
  );
}
